import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';
import {Theme} from '@emotion/react';

const theme: Theme = createTheme({
  typography: {
    fontFamily: '"Encode Sans", sans-serif',
  },
  palette: {
    primary: {
      main: '#1F1F1F',
    },
    secondary: {
      main: '#FFC107',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
