import {useEffect} from 'react';

import {Avatar, Box, Grid, Typography} from '@mui/material';
import {HandymanOutlined} from '@mui/icons-material';

import './App.scss';

function App() {
  useEffect((): void => document.getElementById('init-preloader')?.remove(), []);

  return (
    <Grid container component="main" sx={{height: '100vh', bgcolor: 'primary.main'}}>
      <Grid item xs={12}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{m: 2, bgcolor: 'secondary.main', width: 55, height: 55}}>
            <HandymanOutlined color='primary' fontSize="large"/>
          </Avatar>
          <Typography component="h1" variant="h4" textAlign='center' color='secondary.main'>
            Página en construcción
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default App
